<!--
 * @version: 1.0
 * @Date: 2023-05-30 16:05:13
 * @LastEditors: 杨慢慢
-->
/* 盖印方式配置 */
<template>
  <a-form-item label="选项" class="options" required>
    <draggable
      :list="checkList"
      handle=".rank-item"
      :sort="true"
      :animation="300"
      itemKey="name"
      @sort="sortDraggable"
      :disabled="config?.allDisabled"
    >
      <template #item="{ element,index }">
        <div style="margin-bottom: 8px">
          <div class="flex-layout" style="align-items: center; ">
            <div class="rank-item">
              <div>{{ element.name }}</div>
              <span class="rank">
                <i class="iconfont icon-sort"></i>
              </span>
            </div>
            <a-switch
              v-model:checked="checkList[index].isOpen"
              @change="change(index)"
              :disabled="config?.allDisabled"
            />
          </div>
          <div style="margin-top: 6px;">
            <a-checkbox-group
              v-model:value="checkList[index].childSelect"
              @change="val => checkedChange(val, index)"
              :disabled="config?.allDisabled ? config?.allDisabled : !checkList[index].isOpen"
            >
              <a-row>
                <a-col v-for="item in selectOptions" :key="item.id" style="margin-bottom: 8px;margin-right: 40px;">
                  <a-checkbox :value="item.id">{{ item.value }}</a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </div>
      </template>
    </draggable>
  </a-form-item>
</template>

<script setup>
import { defineProps, ref, onMounted, computed, reactive, watch } from 'vue'
import { cmsNotice } from '@/utils/utils'
import draggable from 'vuedraggable'
import { useStore } from 'vuex'

const props = defineProps(['config'])

const store = useStore()

const basicForm = computed({
  get() {
    return store.state.process.basic
  },
  set(val) {
    store.state.process.basic = val
  }
})

const checkList = computed(() => {
  if (props.config.options != null) {
    return JSON.parse(JSON.stringify(props.config.options))
  } else {
    return [
      {
        name: '常规盖印',
        isOpen: true,
        childSelect: []
      },
      {
        name: '连续盖印',
        isOpen: true,
        childSelect: [1, 2]
      },
      {
        name: '远程盖印',
        isOpen: true,
        childSelect: [1, 2]
      }
    ]
  }
})

onMounted(() => {
  console.log('这是选项', props.config.options, basicForm.value)
})

const change = index => {
  console.log(index)

  //这里赋值值才有变化
  props.config.options = checkList.value
  const checkedList = props.config.options.find(it => it.isOpen)
  console.log('checkedList', checkedList)
  if (!checkedList) {
    props.config.options[index]['isOpen'] = true
    return cmsNotice('warning', '此组件至少显示一个选项')
  }

  //当二维码水印开启单页时，开启连续盖印模式提示用户
  const cur = props.config.options[index]
  if (cur.name == '连续盖印') {
    const qrcodeType = basicForm.value.qrcodeType
    const qrcodePageType = basicForm.value.qrcodePageType
    const ocrType = basicForm.value.ocrType
    const ocrPattern = basicForm.value.ocrPattern
    if (cur.isOpen && qrcodeType == 1 && qrcodePageType == 2) {
      props.config.options[index]['isOpen'] = false
      return cmsNotice('warning', '连续用印不支持二维码水印--单页盖印模式。')
    }
    if (cur.isOpen && ocrType == 1 && ocrPattern == 2) {
      props.config.options[index]['isOpen'] = false
      return cmsNotice('warning', '连续用印不支持ocr文件对比--单页拍摄盖印。')
    }
  }

  //开启远程判断是否开启的二维码水印
  if (cur.name == '远程盖印') {
    const qrcodeType = basicForm.value.qrcodeType
    const ocrType = basicForm.value.ocrType
    if (qrcodeType == 1) {
      props.config.options[index]['isOpen'] = false
      return cmsNotice('warning', '远程盖印不支持二维码水印')
    }
    if (ocrType == 1) {
      console.log('111111111111111111', props.config.options[index]['isOpen']);
      if (!props.config.options[index]['isOpen']) return;
      props.config.options[index]['isOpen'] = false
      return cmsNotice('warning', '远程盖印不支持ocr文件对比')
    }
  }

  //关闭盖印取消对印范围用印与录制视频勾选
  if (!cur.isOpen) {
    props.config.options[index]['childSelect'] = []
  }
}
// 拖拽
const sortDraggable = () => {
  console.log(111, checkList.value)
  props.config.options = checkList.value
}
const checkedChange = (val, index) => {
  if (props.config.options[index]) {
    if (basicForm.value.equipmentRec == 1) {
      let old = props.config.options[index]['childSelect'].find(i => i == 1)
      let newV = val.find(i => i == 1)
      if (old && !newV) {
        let name = props.config.options[index].name
        cmsNotice('warning', `${name}范围内用印已关闭，天玺将不会拍摄视频`)
      }
    }
    props.config.options[index]['childSelect'] = val
  }
}

const selectOptions = reactive([
  {
    id: 1,
    value: '范围用印'
  },
  {
    id: 2,
    value: '录制视频'
  }
])
</script>

<style lang="less" scoped>
.rank-item {
  width: 256px;
  height: 32px;
  background: #f5f5f5;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  margin-right: 8px;
  cursor: pointer;
}

.options {
  margin-bottom: 0px;
}
</style>
